
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    currentHistory: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    updateInput (item) {
      this.$emit(`sendInputValue`, item);
      this.$store.dispatch(`search/setSubmitStatus`, true);
    },
  },
};
