
export default {
  props: {
    error: {
      type: Object,
      default: () => false,
    },
  },
  layout: `error`,
};
