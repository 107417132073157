import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6a4b2c49&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=6a4b2c49&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a4b2c49",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DFPHeader: require('/src/components/DFP/DFPHeader.vue').default,Header: require('/src/components/Header/Header.vue').default,Footer: require('/src/components/Footer/Footer.vue').default,SearchOverlay: require('/src/components/Search/Layouts/SearchOverlay.vue').default,CookieNotice: require('/src/components/CookieNotice/CookieNotice.vue').default,OneTrust: require('/src/components/OneTrust.vue').default})
