
export default {
  props: {
    menu: { type: Array, default: () => [] },
    topHeader: { type: Boolean, default: false },
  },
  data () {
    return {
      menuItem: [],
    };
  },
  methods: {
    generateMenuItem (item) {
      this.menuItem = item;
    },
  },
};
