import { render, staticRenderFns } from "./SearchFull.vue?vue&type=template&id=b80aa868&scoped=true&"
import script from "./SearchFull.vue?vue&type=script&lang=js&"
export * from "./SearchFull.vue?vue&type=script&lang=js&"
import style0 from "./SearchFull.vue?vue&type=style&index=0&id=b80aa868&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b80aa868",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchArticle: require('/src/components/Search/Information/FullSearch/SearchArticle.vue').default})
