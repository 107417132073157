import Vue from 'vue';

const options = {
  rootMargin: `0px 0px 100px 0px`,
};

const lazyObserver = new IntersectionObserver((entries, lazyObserver) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.__vue__._data.trigger = true;
      lazyObserver.unobserve(entry.target);
    }
  });
}, options);

Vue.directive(`lazy`, {
  bind: el => {
    el.dataset.lazy = ``;
    lazyObserver.observe(el);
  },
});
