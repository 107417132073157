const anchorTag = `a`;
const nuxtLink = `nuxt-link`;

const returnLinkTag = ({ href, blank }) => {
  if (blank) { // condition handles cases where link is internal, but `_blank` is used; in this case, an anchor tag is needed
    return anchorTag;
  } else if (!href.includes(`http`)) { // else if the link does not contain a full `http[s]` link, then it is internal and needs a nuxt-link tag
    return nuxtLink;
  }
  return anchorTag; // otherwise: if it's an external link, then return an anchor tag
};

export { returnLinkTag as default, anchorTag, nuxtLink };
