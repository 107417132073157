
export default {
  data () {
    return {
      topMenu: [],
    };
  },
  mounted () {
    this.getTopMenu();
  },
  methods: {
    async getTopMenu () {
      const request = await this.$getPosts(`/menu?id=top-menu`);
      this.topMenu = request;
    },
  },
};
