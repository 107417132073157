import axios from 'axios';
import qs from 'qs';

export default (context, inject) => {
  const posts = async (requestUrl, requestParams) => {
    try {
      const request = await axios.get(`${process.env.baseUrl}/wp-json/sotic-wp-api${requestUrl}`, {
        params: requestParams,
        paramsSerializer: params => qs.stringify(params),
      });
      return request.data.data;
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  };

  inject(`getPosts`, posts);
  context.$getPosts = posts; // Backwards compatibility for Nuxt <= 2.12
};
