import { render, staticRenderFns } from "./FooterPartners.vue?vue&type=template&id=30bd627e&scoped=true&"
import script from "./FooterPartners.vue?vue&type=script&lang=js&"
export * from "./FooterPartners.vue?vue&type=script&lang=js&"
import style0 from "./FooterPartners.vue?vue&type=style&index=0&id=30bd627e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30bd627e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterPartner: require('/src/components/Footer/Partners/FooterPartner.vue').default,Slider: require('/src/components/Slider/Slider.vue').default})
