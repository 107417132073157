export default (context, inject) => {
  const postMeta = post => [
    // HTML DOM
    {
      hid: `description`,
      name: `description`,
      content: (post && post.yoast ? post.yoast.description : post.excerpt),
    },
    // Open Graph
    {
      hid: `og:site_name`,
      name: `og:site_name`,
      content: context.env.siteTitle,
    },
    {
      hid: `og:title`,
      name: `og:title`,
      content: (post ? `${context.env.siteTitle} - ${post.title}` : `${context.env.siteTitle}`),
    },
    {
      hid: `og:description`,
      name: `og:description`,
      content: (post && post.yoast ? post.yoast.description : post.excerpt),
    },
    {
      hid: `og:type`,
      name: `og:type`,
      content: (post && post.type === `page` ? `WebPage` : `article`),
    },
    {
      hid: `og:url`,
      name: `og:url`,
      content: (post ? post.link : ``),
    },
    {
      hid: `og:image`,
      name: `og:image`,
      content: post ? post.image.thumbnail : context.store.state.config.default_page_image.sizes.medium,
    },
    // Twitter Card
    {
      hid: `twitter:card`,
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      hid: `twitter:site`,
      name: `twitter:site`,
      content: context.env.siteHandle,
    },
    {
      hid: `twitter:creator`,
      name: `twitter:creator`,
      content: context.env.siteHandle,
    },
    {
      hid: `twitter:title`,
      name: `twitter:title`,
      content: (post ? `${context.env.siteTitle} - ${post.title}` : `${context.env.siteTitle}`),
    },
    {
      hid: `twitter:description`,
      name: `twitter:description`,
      content: (post && post.yoast ? post.yoast.description : post.excerpt),
    },
    {
      hid: `twitter:image`,
      name: `twitter:image`,
      content: (post ? post.image.thumbnail : context.store.state.config.default_page_image.sizes.medium),
    },
  ];

  inject(`postMeta`, postMeta);
  context.$postMeta = postMeta; // Backwards compatibility for Nuxt <= 2.12
};
