
export default {
  data () {
    return {
      currentHistory: null,
      popularSearches: false,
    };
  },
  mounted () {
    if (window.localStorage.getItem(`history`) !== null) {
      this.currentHistory = JSON.parse(window.localStorage.getItem(`history`))
        .reverse()
        .slice(0, 20);
    };
  },
  methods: {
    sendInputValue (val) {
      this.$parent.searchTerm = val;
      this.$parent.fullSearch = true;
    },
  },
};
