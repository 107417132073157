export default {
  state: () => ({
    post: {},
    isHomepage: false,
    mainMenu: [],
    taxonomies: {},
    colourScheme: `primary`,
    additionalConfig: {},
  }),
  mutations: {
    SET_POST (state, post) {
      state.post = post;
    },
    SET_HOMEPAGE (state, value) {
      state.isHomepage = value;
    },
    SET_MENU (state, menu) {
      state.mainMenu = menu;
    },
    SET_TAXONOMIES (state, taxonomies) {
      state.taxonomies = taxonomies;
    },
    SET_COLOUR_SCHEME (state, colourScheme) {
      state.colourScheme = colourScheme;
    },
    SET_ADDITIONAL_CONFIG (state, additionalConfig) {
      if (additionalConfig) {
        // Convert additionalConfig to an object that uses the title as the key
        const additionalConfigObj = {};
        additionalConfig.forEach(item => {
          additionalConfigObj[item.title.replace(/ /g, `_`).toLowerCase()] = item.acf;
        },
        );
        state.additionalConfig = additionalConfigObj;
      } else {
        state.additionalConfig = additionalConfig;
      }
    },
  },
  actions: {
    setPost ({ commit }, post) {
      commit(`SET_POST`, post);
    },
    setTaxonomies ({ commit }, taxonomies) {
      commit(`SET_TAXONOMIES`, taxonomies);
    },
    setColourScheme ({ commit }, colourScheme) {
      if (colourScheme) {
        commit(`SET_COLOUR_SCHEME`, `commercial`);
      } else {
        commit(`SET_COLOUR_SCHEME`, `primary`);
      }
    },
    setAdditionalConfig ({ commit }, config) {
      commit(`SET_ADDITIONAL_CONFIG`, config);
    },
    isHomepage ({ commit }, value) {
      commit(`SET_HOMEPAGE`, value);
    },
    async getMenu ({ commit }) {
      // NOTE: Site will break unless a menu called 'Main Menu' is created in WP
      try {
        const { data } = await this.$axios.$get(`${process.env.baseUrl}/wp-json/sotic-wp-api/menu?id=main-menu`);
        commit(`SET_MENU`, data);
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    },
  },
};
