import { render, staticRenderFns } from "./DesktopNav.vue?vue&type=template&id=63420f10&"
import script from "./DesktopNav.vue?vue&type=script&lang=js&"
export * from "./DesktopNav.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuList: require('/src/components/Header/Sections/Navigation/MenuList.vue').default})
