
export default {
  props: {
    pages: {
      type: [Array, Object],
      default: () => [],
    },
    active: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: ``,
    },
    type: {
      type: String,
      default: ``,
    },
    extractedDropdownParent: {
      type: Object,
      default: () => ({}),
    },
  },
};
