
export default {
  props: {
    searchTerm: {
      type: String,
      default: ``,
    },
  },
  data () {
    return {
      allTabs: [],
      selectedType: `post`,
      displayTypes: [`post`, `page`],
    };
  },
  computed: {
    allTypes () {
      const allTypes = [];
      Object.values(this.searchChannels).forEach(types =>
        allTypes.push(...Object.keys(types)),
      );
      return [...allTypes];
    },
    moreTypes () {
      const allTypesCopy = [...this.allTypes];
      return allTypesCopy.filter(tab => !this.displayTypes.includes(tab));
    },
    searchContainsSpace () {
      return this.searchTerm.includes(` `);
    },
    nameFormat () {
      if (this.searchContainsSpace) {
        return this.searchTerm.split(` `);
      }
      return this.searchTerm;
    },
  },
  watch: {
    $route () {
      this.$store.dispatch(`search/setSearchStatus`, false);
      this.$store.dispatch(`search/setSubmitStatus`, false);
    },
  },
  mounted () {
    this.allTabs = document.querySelectorAll(`.search-tab`);
    this.$refs.tab && this.$refs.tab[0].classList.add(`search-tab--active`);
    this.searchTypesAndAssignResponseData({
      type: { wpApiTypes: [this.selectedType] },
      searchTerm: this.searchTerm,
      numPosts: 10,
    });
  },
  methods: {
    // INTERFACES:
    selectType ({ type }) {
      this.selectedType = type;
      this.searchTypesAndAssignResponseData({
        // TODO: abstract repeated code into method
        type: {
          [`${
            this.searchChannelKeys?.wpApiTypes?.includes(type)
              ? `wpApiTypes`
              : `statsApiTypes`
          }`]: [type],
        },
        searchTerm: this.searchTerm,
        numPosts: 10,
      });
      this.allTabs.forEach(tab => {
        if (tab.getAttribute(`data-value`) === type) {
          tab.classList.add(`search-tab--active`);
          tab.setAttribute(`aria-selected`, true);
        } else {
          tab.classList.remove(`search-tab--active`);
          tab.setAttribute(`aria-selected`, false);
        }
      });
    },
    loadMore ({ type }) {
      this.searchTypesAndAssignResponseData({
        type: {
          [`${
            this.searchChannelKeys?.wpApiTypes?.includes(type)
              ? `wpApiTypes`
              : `statsApiTypes`
          }`]: [type],
        },
        searchTerm: this.searchTerm,
        numPosts: 10,
        offset: 10,
      });
    },
    // ABSTRACTION BARRIER:
    slugify (name) {
      return name.replace(/ /g, `-`).replace(/^-+/, ``)
        .replace(/-+$/, ``);
    },
  },
};
