export default (context, inject) => {
  const params = {
    headers: {
      'Access-Control-Allow-Origin': `*`,
    },
  };

  const redirection = async url => {
    try {
      const request = await context.$axios.get(`${process.env.baseUrl}/wp-json/sotic-wp-api/redirection?id=${url}`, params);
      return request.data;
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  };

  if (redirection) {
    inject(`getRedirection`, redirection);
    context.$getRedirection = redirection;

    // Check if we need this :/
    const redirect = redirectPath => {
      context.router.push({
        path: redirectPath,
      });
    };
    inject(`redirect`, redirect);
    context.$redirect = redirect;
  }
};
