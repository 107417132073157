export default {
  computed: {
    globalPost () {
      return this.$store?.state?.post?.post;
    },
    isHomepage () {
      return this.$store?.state?.post?.isHomepage;
    },
    siteSettings () {
      return this.$store?.state?.config;
    },
    additionalConfig () {
      return this.$store?.state?.wp?.additionalConfig;
    },
    languageSettings () {
      if (this.siteSettings && this.siteLanguage && process.env.multilanguage) {
        return this.siteSettings[this.siteLanguage]?.translatable_site_settings;
      }
      return false;
    },
    statGroups () {
      return this.$store?.state?.config?.stat_groups;
    },
    defaultData () {
      return this.$store?.state?.config?.default_data;
    },
    defaultWomenData () {
      return this.$store?.state?.config?.default_women_data;
    },
    commercialColourScheme () {
      // NOTE: need to make colour scheme dependent on 'this.$route.path' because we need the colourScheme to update when the $route changes
      if (this.$route?.path) {
        return this.$store?.state?.wp?.colourScheme === `commercial` ?? false;
      }
    },
    redTVLogo () {
      return this.$store?.state?.config?.red_tv_logo;
    },
  },
  methods: {
    /*
     *  Store global post information
     */
    setPostData (post, isHomepage = false) {
      this.$store.dispatch(`wp/setPost`, post);
      this.$store.dispatch(`wp/isHomepage`, isHomepage);
    },
    metaQuery (key, value, compare = `=`, type = `CHAR`) {
      return [
        [
          {
            key,
            value,
            type,
            compare,
          },
        ],
      ];
    },
    pinnedPostsQuery (pinned = true) {
      const compare = pinned ? `LIKE` : `NOT LIKE`;
      return this.metaQuery(`pin_post`, `1`, compare, `BINARY`);
    },
    /**
     * Returns a meta query for a taxonomy, to be used with the `meta_query` parameter
     * @param  {string} id The ID of the taxonomy being searched for
     * @return {array} A meta query array to be used for the WP API
     */
    taxonomyQuery (id) {
      return [
        [
          {
            key: `sotic_taxonomies`,
            value: id,
            compare: `LIKE`,
          },
        ],
      ];
    },
    getPageType (post) {
      if (post?.terms.length) {
        const term = post.terms.find(term => term.taxonomy === `page_type`);
        if (term) {
          return term.slug;
        }
      }
      return false;
    },
    /*
        Retrieve a single category to display on a post. In order of priority:
        - The primary category
        - The first category
        - The post type
    */
    getCategory (post) {
      const type = post.post_type
        ? this.deslugify(post.post_type)
        : this.deslugify(post.type);

      // ACF post object
      if (post.post_categories) {
        // ACF post objects have no primary category
        const cleanCat = post.post_categories.find(
          cat => cat.cat_name.toLowerCase() !== `uncategorized`,
        );
        return cleanCat ? cleanCat.cat_name : type.toLowerCase() === `post` ? `News` : type;
      }

      // API post object
      if (post.categories) {
        const primaryCategory = post.categories.find(
          category => category.primary,
        );
        if (primaryCategory) {
          return primaryCategory.name;
        }

        const cleanCat = post.categories.find(
          cat => cat.name.toLowerCase() !== `uncategorized`,
        );
        return cleanCat ? cleanCat.name : type.toLowerCase() === `post` ? `News` : type;
      }

      // Return post type
      return type.toLowerCase() === `post` ? `News` : this.deslugify(type);
    },
    /*
     *  Retrieve a selected stat group by its title
     */
    getStatGroup (title) {
      return this.statGroups.find(group => group.title === title);
    },
    getDefault (entity, team = `men`) {
      if (team === `men`) {
        if (this.defaultData[entity]) {
          return this.defaultData[entity][entity].length
            ? this.defaultData[entity][entity][0]
            : false;
        }
      }

      if (team === `women`) {
        if (this.defaultWomenData[entity]) {
          return this.defaultWomenData[entity][entity].length
            ? this.defaultWomenData[entity][entity][0]
            : false;
        }
      }
      return false;
    },
    /*
     *  Get taxonomy data; returns all data or single data by key
     */
    getTaxonomies (post, key = false) {
      if (post.meta && post.meta?.sotic_taxonomies?.length && post.meta?.sotic_taxonomies[0]) {
        // eslint-disable-next-line
        const taxonomies = phpUnserialize(post.meta.sotic_taxonomies[0]);
        return (key && taxonomies[key]) ? Object.values(taxonomies[key]) : taxonomies;
      }
      return [];
    },
    getPostTypeLabel (type) {
      return type.toLowerCase() === `post`
        ? `News`
        : type === `match_preview`
          ? `Preview`
          : this.deslugify(type);
    },
    getPartnerLogo (post, type = `dark`) {
      if (type === `dark`) {
        return post.image.thumbnail;
      }
      if (type === `light`) {
        return post.acf.light_logo.url;
      }
    },
    hasLightLogo (post) {
      return !!post.acf.light_logo;
    },
    getPlayerStatKey (stat) {
      const statKey = stat.stat.playerstattype.length
        ? stat.stat.playerstattype[0]?.displayName
        : stat.stat.computedplayerstattype[0]?.displayName;
      return statKey;
    },
    getTeamStatKey (stat) {
      return stat.stat.teamstattype.length
        ? stat.stat.teamstattype[0].displayName
        : stat.stat.computedteamstattype[0].displayName;
    },

  },
};
