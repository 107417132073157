
import _ from "lodash";

export default {
  props: {
    searchTerm: {
      type: String,
      default: ``,
    },
  },
  computed: {
    searchContainsSpace () {
      return this.searchTerm.includes(` `);
    },
    nameFormat () {
      if (this.searchContainsSpace) {
        return this.searchTerm.split(` `);
      }
      return this.searchTerm;
    },
  },
  watch: {
    searchTerm: _.debounce(async function (searchTerm) {
      const types = {
        wpApiTypes: Object.values(this.searchChannelKeys)[0],
        statsApiTypes: Object.values(this.searchChannelKeys)[1],
      };
      await this.searchTypesAndAssignResponseData({ types, searchTerm });
    }, 500),
    $route () {
      this.closeSearch(-1);
    },
  },
  async mounted () {
    // TODO: abstract this repeated code into a method
    const types = {
      wpApiTypes: Object.values(this.searchChannelKeys)[0],
      statsApiTypes: Object.values(this.searchChannelKeys)[1],
    };
    await this.searchTypesAndAssignResponseData({
      types,
      searchTerm: this.searchTerm,
    });
  },
  methods: {
    closeSearch () {
      this.$store.dispatch(`search/setSearchStatus`, false);
    },
  },
};
