
export default {
  data () {
    return {
      hasScrolled: false,
    };
  },
  computed: {
    hasSitePartner () {
      return this.siteSettings?.header_site_partner?.length > 0;
    },
  },
  beforeMount () {
    window.addEventListener(`scroll`, this.handleScroll);
  },
  destroyed () {
    window.removeEventListener(`scroll`, this.handleScroll);
  },
  methods: {
    generateMenuItem (item) {
      this.menuItem = item;
    },
    handleScroll (e) {
      this.hasScrolled = window.scrollY >= 61;
      this.$emit(`has-scrolled`, this.hasScrolled);
    },
  },
};
