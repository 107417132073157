
import _ from 'lodash';

export default {
  props: {
    searchTerm: {
      type: String,
      default: ``,
    },
  },
  data () {
    return {
      searchValue: null,
      currentHistory: null,
    };
  },
  computed: {
    fullSearch () {
      return this.$store.state.search.searchStatus;
    },
    submitStatus () {
      return this.$store.state.search.submitStatus;
    },
  },
  watch: {
    searchValue: _.debounce(function (val) {
      this.$emit(`sendValue`, val);
    }, 1000),
    searchTerm (val) {
      this.searchValue = val;
    },
  },
  mounted () {
    if (window.localStorage.getItem(`history`) !== null) {
      this.currentHistory = JSON.parse(window.localStorage.getItem(`history`));
    }
    this.$refs.input.focus();
  },
  methods: {
    updateSearchValue (e) {
      this.searchValue = e.target.value;
    },
    handleSearch (e) {
      e.preventDefault();
      if (this.submitStatus) {
        this.$store.dispatch(`search/setSubmitStatus`, false);
        this.searchValue = ``;
        this.$emit(`fullSearch`, false);
        this.$refs.input.focus();
      } else if (this.searchValue !== ``) {
        this.$emit(`fullSearch`, true);
        this.$store.dispatch(`search/setSubmitStatus`, true);
        let tempArray;
        if (this.currentHistory !== null) {
          tempArray = [...this.currentHistory, this.searchValue.toLowerCase()];
        } else {
          tempArray = [this.searchValue.toLowerCase()];
        }
        localStorage.setItem(`history`, JSON.stringify(tempArray));
        this.currentHistory = JSON.parse(localStorage.getItem(`history`));
      }
    },
  },
};
