
export default {
  data () {
    return {};
  },
  computed: {
    getCurrentRoute () {
      return this.$route.path;
    },
  },
};
