import { render, staticRenderFns } from "./SearchOverlay.vue?vue&type=template&id=68e8ce62&scoped=true&"
import script from "./SearchOverlay.vue?vue&type=script&lang=js&"
export * from "./SearchOverlay.vue?vue&type=script&lang=js&"
import style0 from "./SearchOverlay.vue?vue&type=style&index=0&id=68e8ce62&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68e8ce62",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchBar: require('/src/components/Search/Layouts/SearchBar.vue').default,SearchSuggestions: require('/src/components/Search/Information/Suggestions/SearchSuggestions.vue').default,SearchFull: require('/src/components/Search/Information/FullSearch/SearchFull.vue').default,SearchInitial: require('/src/components/Search/Information/InitialSearch/SearchInitial.vue').default})
