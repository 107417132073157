export const state = () => ({
  searchStatus: false,
  submitStatus: false,
  searchResults: [],
});

export const mutations = {
  SET_SEARCH_STATUS (state, value) {
    state.searchStatus = value;
  },
  SET_SUBMIT_STATUS (state, value) {
    state.submitStatus = value;
  },
  SET_SEARCH_RESULTS (state, value) {
    state.searchResults = value;
  },
};

export const actions = {
  setSearchStatus ({ commit }, value) {
    commit(`SET_SEARCH_STATUS`, value);
  },
  setSubmitStatus ({ commit }, value) {
    commit(`SET_SUBMIT_STATUS`, value);
  },
  setSearchResults ({ commit }, value) {
    commit(`SET_SEARCH_RESULTS`, value);
  },
};

export const getters = {
  getSortedResults: state => {
    const availableCategories = [];
    state.searchResults.forEach(article => !availableCategories.includes(article.type) && availableCategories.push(article.type));

    const sortedResults = {
    };
    availableCategories.forEach(category => {
      sortedResults[category] = [];
    });

    state.searchResults.forEach(post => {
      sortedResults[post.type].push(post);
    });

    return sortedResults;
  },
};
