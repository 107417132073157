
import Vue from "vue";
import global from "~/mixins.js/global.js";
import language from "~/mixins.js/language.js";
import wp from "~/mixins.js/wp.js";
import search from "~/mixins.js/search.js";

Vue.mixin(global);
Vue.mixin(language);
Vue.mixin(wp);
Vue.mixin(search);

export default {
  data () {
    return {
      hasScrolled: false,
    };
  },
  computed: {
    searchStatus () {
      return this.$store.state.search.searchStatus;
    },
    showPopup () {
      return this.$store.state.config.show_popup_toggle;
    },
    enableOneTrust () {
      return this.$store.state.config.enable_onetrust;
    },
  },
  watch: {
    searchStatus (val) {
      val
        ? (document.querySelector(`body`).style.overflowY = `hidden`)
        : (document.querySelector(`body`).style.overflowY = `scroll`);
    },
  },
  beforeMount () {
    if (window.location.hostname === process.env.undesiredCanonical) {
      window.location.replace(
        process.env.desiredCanonical + window.location.pathname,
      );
    }
    // Get Language
    const lang = localStorage.getItem(`language`) || `en`;
    this.setLang(lang);
    this.$store.dispatch(`language/getStringTranslations`);

    // Get Config
    this.getAdditionalConfig();
  },
  mounted () {
    this.$store.dispatch(`language/getLanguages`, false);
  },
  methods: {
    handleScroll (val) {
      this.hasScrolled = val;
    },
    async getAdditionalConfig () {
      // Get additional config data
      const params = {
        fields: `acf,title`,
        posts: {
          post_type: [`additional_config`],
          posts_per_page: 50,
          post_status: `publish`,
        },
      };

      const config = await this.$getPosts(`/posts`, params);
      if (config !== null) {
        this.$store.dispatch(`wp/setAdditionalConfig`, config);
      }
    },
  },
};
