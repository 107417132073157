import axios from 'axios';

export default {
  state: () => ({
    language: `en`,
    languages: [],
    stringTranslations: [],
  }),
  mutations: {
    SET_LANGUAGE (state, lang) {
      localStorage.setItem(`language`, lang);
      state.language = lang;
    },
    SET_ALL_LANGUAGES (state, languages) {
      state.languages = languages;
    },
    SAVE_TRANSLATIONS (state, translations) {
      state.stringTranslations = translations?.string_translations;
    },
  },
  actions: {
    getLanguages ({ commit }) {
      return process.env.multilanguage
        ? axios.get(`${process.env.baseUrl}/wp-json/sotic-wp-api/languages`).then(response => {
          commit(`SET_ALL_LANGUAGES`, response.data.data);
        })
        : commit(`SET_ALL_LANGUAGES`, [`en`]);
    },
    setLanguage ({ commit }, lang) {
      commit(`SET_LANGUAGE`, lang);
    },
    getStringTranslations ({ commit }, context) {
      return axios.get(`${process.env.baseUrl}/wp-json/sotic-wp-api/options?id=language-settings`).then(response => {
        commit(`SAVE_TRANSLATIONS`, response.data.data);
      });
    },
  },
};
