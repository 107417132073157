
export default {
  data () {
    return {
      search: ``,
      placeholder: process.env.siteTitle,
      searchActive: false,
    };
  },
  methods: {
    submit () {
      // this.$router.push(`/search?search=${this.search}`);
    },
    openSearch () {
      this.$store.dispatch(`search/setSearchStatus`, true);
    },
  },
};
