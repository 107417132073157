
export default {
  data () {
    return {
      date: new Date(),
      expires: `; expires:${new Date().toGMTString()}`,
      OABCcookieName: `OptanonAlertBoxClosed`,
    };
  },
  computed: {
    cookieIcon () {
      return require(`~/assets/icons/cookie-icon.svg`);
    },
  },
  mounted () {
    this.date.setTime(this.date.getTime() + (365 * 24 * 60 * 60 * 1000));
  },
  methods: {
    OptanonWrapper () {
      const bannerAcceptButton = document.getElementById(`onetrust-accept-btn-handler`);
      const pcAllowAllButton = document.getElementById(`accept-recommended-btn-handler`);
      const pcSaveButton = document.getElementsByClassName(`save-preference-btn-handler onetrust-close-btn-handler button-theme`)[0];
      const OABCcookie = this.getCookie(this.OABCcookieName);

      // IF logic needed here because ot-banner-sdk DIV is not injected on page loads if banner is not exposed
      if (!OABCcookie) {
        bannerAcceptButton.addEventListener(`click`, function () {
          console.log(`Allowed all via Banner`); // eslint-disable-line no-console
          // Function to set cookieValue
          setTimeout(this.updateOTExpiration, 1000);
        });
      }

      pcAllowAllButton.addEventListener(`click`, function () {
        console.log(`Allowed all via Preference Center`); // eslint-disable-line no-console
        // Function to set cookieValue
        setTimeout(this.updateOTExpiration, 1000);
      });

      pcSaveButton.addEventListener(`click`, function () {
        console.log(`set custom settings via Preference Center`); // eslint-disable-line no-console
        // Function to set cookieValue
        setTimeout(this.updateOTExpiration, 1000);
      });
    },
    // function to pull cookie value
    getCookie (name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts.pop().split(`;`)
          .shift();
      }
      return false;
    },
    // function to update cookie expiration
    updateOTExpiration () {
      // updating the OptanonAlertBoxClosed cookie expiration date
      // uncomment the domain portion of this for Production CDN scripts
      const OABCcookie = this.getCookie(this.OABCcookieName);
      document.cookie = `${this.OABCcookieName}=${OABCcookie}${this.expires};path=/; Samesite=Lax;`;
      console.log(`OptanonAlertBoxClosed cookie overwritten`); // eslint-disable-line no-console
    },
  },
};
