
export default {
  data () {
    return {
      menu: [],
    };
  },
  mounted () {
    this.$nextTick(() => {
      this.getMenu(`footer-menu`);
    });
  },
  methods: {
    async getMenu (id) {
      const request = await this.$getPosts(`/menu?id=${id}`);
      this.menu = request;
    },
  },
};
