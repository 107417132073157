
// import { exit } from "process";

export default {
  data () {
    return {
      // menu: this.$store.state.wp.mainMenu,
      menu: [],
    };
  },
  computed: {
    footerLinks () {
      const links = this.$store.state.config.footer_links;
      const splitLinks = links.map(currentLink =>
        currentLink.link.url.split(`/`),
      );
      const slugs = splitLinks.map(
        currentLink => currentLink[currentLink.length - 2],
      );
      return links.map((currentLink, index) => ({
        slug: `/${slugs[index]}`,
        title: currentLink.link.title,
        target: currentLink.link.target,
      }));
    },
    year () {
      return new Date().getFullYear();
    },
    siteTitle () {
      return process.env.siteTitle;
    },
    contactDetails () {
      return this.siteSettings.contact_details;
    },
    deliveredByDetails () {
      return this.$store.state.config.delivered_by_details;
    },
    charityNumber () {
      return this.siteSettings.footer_charity_number;
    },
  },
  mounted () {
    this.$nextTick(() => {
      // We'll comment out for now as footer will likely need a different menu for footer
      this.getMenu(`Footer-secondary-menu`);
    });
  },
  methods: {
    async getMenu (id) {
      const request = await this.$getPosts(`/menu?id=${id}`);
      this.menu = request;
    },
  },
};
