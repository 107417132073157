
export default {
  data () {
    return {
      isMenuActive: false,
    };
  },
  methods: {
    toggleMenu (e) {
      e.preventDefault();
      this.isMenuActive = !this.isMenuActive;
    },
    openSearch (e) {
      e.preventDefault();
      this.$store.dispatch(`search/setSearchStatus`, true);
    },
  },
};
