import axios from "axios";
import qs from 'qs';

export default (context, inject) => {
  const searchStats = async requestParams => {
    // https://search-api-staging.aberdeen.soticclient.net/search?q=aber&entity=player
    try {
      const request = await axios.get(
        `${process.env.searchAPIUrl}/search`, {
          params: requestParams,
          paramsSerializer: params => qs.stringify(params),
          maxRedirects: 0,
        },
      );

      return request.data;
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  };

  inject(`getSearchStats`, searchStats);
  context.$getSearchStats = searchStats; // Backwards compatibility for Nuxt <= 2.12
};
