export default {
  data () {
    return {
      isLoading: false,
      searchChannels: {
        wpApiTypes: {
          post: {
            displayName: `Posts`,
            data: [],
          },
          page: {
            displayName: `Pages`,
            data: [],
          },
          video: {
            displayName: `Videos`,
            data: [],
          },
          gallery: {
            displayName: `Gallery`,
            data: [],
          },
        },
        statsApiTypes: {
        },
      },
    };
  },
  computed: {
    activeData () {
      let activeData = [];
      Object.keys(this.availableTypes).forEach(searchChannelKey => {
        if (this.availableTypes?.[searchChannelKey]?.[this.selectedType]?.data?.length) {
          activeData = [
            ...this.availableTypes[searchChannelKey][this.selectedType]?.data,
          ];
        }
      });

      return activeData.length ? activeData : [];
    },
    noResults () {
      return !this.activeData.length > 0;
    },
    searchChannelKeys () { // returns the Object keys for both search channels themselves (`wpApiTypes`, `searchApiTypes`), as well as the type keys underneath them
      const searchChannelKeys = {};
      Object.entries(this.searchChannels).forEach(searchChannels => {
        searchChannelKeys[searchChannels[0]] = Object.keys(searchChannels[1]);
      });
      return { ...searchChannelKeys };
    },
    availableTypes () {
      const availableTypes = {};
      Object.entries(this.searchChannels).forEach(searchChannel => {
        availableTypes[searchChannel[0]] = Object.fromEntries(
          Object.entries(searchChannel[1]).filter(
            type => type[1].data?.length,
          ),
        );
      });

      if (availableTypes?.statsApiTypes?.fixture?.data?.length) {
        availableTypes.statsApiTypes.fixture.data = this.assignCustomPropsToActiveData({
          activeData: availableTypes.statsApiTypes.fixture.data,
          newPropKey: `slug`,
          newPropValueFn: activeDataEntry => `/match-centre?ref=${activeDataEntry.id}`,
        });
      }

      return { ...availableTypes };
    },
  },
  methods: {
    // INTERFACES:
    async searchTypesAndAssignResponseData ({
      type, // NOTE: should either be passing in a single `type`, or all `types`
      types,
      searchTerm,
      numPosts,
      offset,
    }) {
      const apiResults = await this.getApiArticles({
        types: type || types,
        searchTerm,
        numPosts,
        offset,
      });

      if (!apiResults) return;

      if (type) {
        const apiResult = apiResults[Object.keys(type)[0]][0];
        if (!apiResult || !apiResult.length > 0) {
          this.searchChannels[Object.keys(type)[0]][Object.values(type)[0]].data = [];
        }
        this.searchChannels[Object.keys(type)[0]][Object.values(type)[0]].data =
          apiResult;
        return;
      }

      Object.keys(apiResults).forEach(typeKey => {
        apiResults[typeKey].forEach((result, index) => {
          const resultTarget =
            this.searchChannels[typeKey][
              Object.keys(this.searchChannels[typeKey])[index]
            ];
          if (resultTarget && result) resultTarget.data = result;
        }); // TODO: see if we can access associatively based on the return values from getApiArticles(); though, we can rely on the order of promises returned from getApiArticles(), so it's optional
      });
    },
    // ABSTRACTION BARRIER:
    async getApiArticles ({ types, searchTerm, numPosts = 6, offset = 0 }) {
      const requestPromises = { wpApiTypes: [], statsApiTypes: [] };
      const requestResults = this.objectDeepCopy(requestPromises);

      const typeKeys = Object.keys(types);
      for (const typeKey of typeKeys) {
        for (const type of Object.values(types[typeKey])) {
          requestPromises[typeKey].push(
            typeKey === `wpApiTypes`
              ? this.$getPosts(`/posts`, {
                fields: `id,title,categories,date,image,slug,type,excerpt,acf`,
                posts: {
                  post_type: type,
                  posts_per_page: numPosts,
                  offset,
                  s: searchTerm,
                },
              }).catch(_ => null)
              : this.$getSearchStats({
                q: searchTerm,
                entity: type,
                limit: numPosts,
              }).catch(_ => null),
          );
        }
      }

      this.isLoading = true; // REVISIT: side-effect, but seems the only way to successfully trigger the loader at the right time

      const settleAllRequestPromisesAndAssignResults = async (type, fn) => {
        await Promise.allSettled(requestPromises[type]).then(
          requestPromisesResults => {
            requestPromisesResults.forEach(result => {
              const resultValue = fn(result);
              if (resultValue !== null) {
                requestResults[type].push(resultValue);
              }
            });
          },
        );
      };

      await settleAllRequestPromisesAndAssignResults(`wpApiTypes`, result =>
        result?.value ? result.value : null,
      );
      await settleAllRequestPromisesAndAssignResults(
        `statsApiTypes`,
        result => (result?.value ? Object.values(result.value)[0] : null),
      );

      this.isLoading = false;

      return requestResults;
    },
    assignCustomPropsToActiveData ({ activeData, newPropKey, newPropValueFn }) {
      return activeData.map(
        ({ ...activeDataEntry }) => {
          activeDataEntry[newPropKey] = newPropValueFn(activeDataEntry);
          return activeDataEntry;
        });
    },
  },
};
