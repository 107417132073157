
export default {
  props: {
    isMenuActive: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      menu: this.$store.state.wp.mainMenu,
      topMenu: [],
      childrenOpen: false,
    };
  },
  watch: {
    $route () {
      this.$parent._data.isMenuActive = false;
    },
  },
  mounted () {
    this.getTopMenu(); // REVISIT: logic repeats in TopHeader.vue
  },

  methods: {
    handleChildMenu (e) {
      e.currentTarget.classList.toggle(`open`);
      const childMenu = e.currentTarget.nextElementSibling;
      const menuLength = childMenu.children.length;
      const height = e.currentTarget.parentElement.offsetHeight * menuLength;
      e.currentTarget.classList.contains(`open`)
        ? (childMenu.style.height = `${height}px`)
        : (childMenu.style.height = 0);
    },
    async getTopMenu () {
      const request = await this.$getPosts(`/menu?id=top-menu`);
      this.topMenu = request;
    },
  },
};
