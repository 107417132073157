import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=01202572&scoped=true&"
import script from "./Loader.vue?vue&type=script&lang=js&"
export * from "./Loader.vue?vue&type=script&lang=js&"
import style0 from "./Loader.vue?vue&type=style&index=0&id=01202572&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01202572",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LightLoader: require('/src/components/Loaders/LightLoader.vue').default,DarkLoader: require('/src/components/Loaders/DarkLoader.vue').default})
