
export default {
  props: {
    searchTerm: {
      type: String,
      default: ``,
    },
    fullSearch: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    $route () {
      this.closeSearch();
    },
  },
  methods: {
    closeSearch () {
      if (this.fullSearch && window.screen.availWidth < 1000) {
        this.enableFullSearch(false);
        this.setInputFocus();
        this.$store.dispatch(`search/setSubmitStatus`, false);
      } else {
        this.$store.dispatch(`search/setSearchStatus`, false);
      }
    },
    setSearchTerm (term) {
      this.$parent.searchTerm = term;
    },
    enableFullSearch (val) {
      this.$parent.fullSearch = val;
    },
    setInputFocus () {
      this.$refs.searchInput.$refs.input.focus();
      this.setSearchTerm(``);
    },
  },
};
