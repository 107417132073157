
export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
    openLeft: {
      type: Boolean,
      default: false,
    },
    revertWidth: {
      type: String,
      default: ``,
    },
  },
  data () {
    return {
      activeChild: [],
      activeItem: false,
    };
  },
  computed: {
    hasChildren () {
      // Check if any of the submenu items have children
      return this.menu.some(item => item.children);
    },
  },
  methods: {
    manageHover (item) {
      if (item.children) {
        this.activeItem = item;
        this.activeChild = item.children;
        return;
      }
      this.activeItem = false;
    },
  },
};
