
export default {
  props: {
    light: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 40,
      validator (size) {
        return size >= 0;
      },
    },
  },
  computed: {
    loaderImg () {
      const loaders = this.$store.state.config.loaders;
      return this.light ? loaders.light.url : loaders.dark.url;
    },
  },
};
