
export default {
  computed: {
    headerCode () {
      return this.$store.state.config.dfp_header_code;
    },
  },
  watch: {
    $route: {
      handler () {
        /* setTimeout(() => {
          window.googletag = window.googletag || { cmd: [] };
          window.googletag.pubads().refresh();
        }, 200); */
      },
      deep: true,
    },
  },
  beforeCreated () {
    window.googletag.destroySlots();
  },
};
