
import returnLinkTag, { anchorTag, nuxtLink } from "./returnLinkTag";
export default {
  functional: true,
  props: {
    href: {
      type: String,
      default: ``,
    },
    ariaLabel: {
      type: String,
      default: ``,
    },
    title: {
      type: String,
      default: ``,
    },
    blank: {
      type: Boolean,
      default: false,
    },
  },
  render (createElement, context) {
    const tag = returnLinkTag(context.props);
    const attrs = {};
    const props = {};
    let on = {}; // this object will contain any native Vue `v-on` events which need to be converted to regular events that can be used in regular anchor tags
    switch (tag) {
    case anchorTag: // anchor tag href is being assigned to attr{} because it cannot accept a prop (since it is regular HTML)
      attrs.href = context.props.href;
      attrs[`aria-label`] = context.props.ariaLabel;
      attrs.title = context.props.title;
      attrs.target = `_blank`;
      attrs.rel = `noopener nofollow`;
      on = { ...context.data.nativeOn };
      delete context.data.nativeOn;
      break;

    case nuxtLink:
      props.to = context.props.href;
      attrs[`aria-label`] = context.props.ariaLabel;
      attrs.title = context.props.title;
      break;

    default:
      break;
    }

    context.data.attrs = attrs;
    context.data.props = props;
    context.data.on = on;

    return createElement(tag, context.data, context.children);
  },
};
