
export default {
  props: {
    partner: {
      type: Object,
      required: true,
    },
    tier: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
    };
  },
  computed: {
    partnerLink () {
      return this.partner.slug || ``;
    },
    stringifiedTitle () {
      return this.partner.title.replace(/\s+/g, `-`).toLowerCase();
    },
  },
};
