import axios from 'axios';
import qs from 'qs';

export default (context, inject) => {
  const fixtureArticles = async fixtureId => {
    try {
      const request = await axios.get(`${process.env.baseUrl}/wp-json/sotic-wp-api/posts`, {
        params: {
          fields: `id,title,excerpt,slug,type,content,acf,terms,meta,readingTime,author,date,time`,
          posts: {
            posts_per_page: 3,
            post_type: [`match_preview`, `live`, `report`],
            meta_query: [
              [
                {
                  key: `sotic_taxonomies`,
                  value: fixtureId,
                  compare: `LIKE`,
                },
              ],
            ],
          },
          options: {
            taxonomy: `page_type`,
          },
        },
        paramsSerializer: params => qs.stringify(params),
      });
      return request.data.data;
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  };

  const fixtureArticle = async fixtureId => {
    try {
      const request = await axios.get(`${process.env.baseUrl}/wp-json/sotic-wp-api/posts`, {
        params: {
          fields: `id,title,slug,type`,
          posts: {
            posts_per_page: 3,
            post_type: [`match_preview`, `live`, `report`],
            meta_query: [
              [
                {
                  key: `sotic_taxonomies`,
                  value: fixtureId,
                  compare: `LIKE`,
                },
              ],
            ],
          },
          options: {
            taxonomy: `page_type`,
          },
        },
        paramsSerializer: params => qs.stringify(params),
      });
      if (request?.data?.data?.length) {
        return request.data.data.find(post => post.type === `report`)
          ? request.data.data.find(post => post.type === `report`)
          : request.data.data.find(post => post.type === `live`)
            ? request.data.data.find(post => post.type === `live`)
            : request.data.data.find(post => post.type === `match_preview`)
              ? request.data.data.find(post => post.type === `match_preview`)
              : null;
      }
      return null;
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  };

  // Get relevant fixture link (e.g. for fixtures page)
  inject(`getFixLink`, fixtureArticle);
  context.$getFixLink = fixtureArticle; // Backwards compatibility for Nuxt <= 2.12

  // Get all editorial content, for match centre etc,
  inject(`getFixArticles`, fixtureArticles);
  context.$getFixArticles = fixtureArticles; // Backwards compatibility for Nuxt <= 2.12
};
