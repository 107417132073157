import axios from 'axios';
import qs from 'qs';

export default (context, inject) => {
  // Get video file
  const video = async videoId => {
    try {
      const credentials = {};

      if (context.store.getters[`auth/hasUser`]) {
        const user = context.store.getters[`auth/getUser`];
        console.log(user); // eslint-disable-line no-console
        credentials.sso_id = user?.id;
        credentials.dons_id = user?.ext_acc_ticketing;
      }

      const request = await axios
        .post(
          `${process.env.redTVVideoUrl}/${videoId}/rendition`,
          credentials,
        );

      let isFullVideo = false;
      if (request?.data?.data?.length > 1) {
        isFullVideo = true;
      }
      return request?.data?.data.length ? {
        video: request.data.data[request.data.data.length - 1],
        isFullVideo,
      }
        : null;
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  };

  // Get videos list
  const videos = async requestParams => {
    try {
      const request = await axios.get(`${process.env.redTVBaseUrl}`, {
        params: requestParams,
        paramsSerializer: params => qs.stringify(params),
      });
      return request?.data?.data;
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  };

  // Get video metadata
  const videoMeta = async videoId => {
    try {
      const request = await axios.get(`${process.env.redTVBaseUrl}/${videoId}`);
      return request?.data?.data;
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  };

  // Return video file and metadata

  inject(`getVideo`, video);
  context.$getVideo = video; // Backwards compatibility for Nuxt <= 2.12

  // Return videos list from params

  inject(`getVideos`, videos);
  context.$getVideos = videos; // Backwards compatibility for Nuxt <= 2.12

  // Return video meta

  inject(`getVideoMeta`, videoMeta);
  context.$getVideoMeta = videoMeta; // Backwards compatibility for Nuxt <= 2.12
};
