
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: ``,
    },
  },
  data () {
    return {
      childActive: false,
      levelLimit: 2, // Third level
      dropdownActive: false,
      extractedDropdownParent: {
        title: ``,
        slug: ``,
      },
    };
  },
  methods: {
    openChild () {
      this.childActive = true;
      this.dropdownActive = false;
      this.$emit(`child-open`);
    },
    closeChild () {
      this.childActive = false;
      this.dropdownActive = false;
      this.$emit(`child-close`);
    },
    extractDropdownItems (title, slug) {
      if (slug && title) {
        this.extractedDropdownParent.title = `${title} Home`;
        this.extractedDropdownParent.slug = slug;
      }
    },
  },
};
