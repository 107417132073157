

import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.min.css';

export default {
  props: {
    sliderName: {
      type: String,
      default: ``,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    activeIndexChange: {
      type: Boolean,
      default: false,
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.initialiseSlider();
    });
  },
  destroyed () {
    // this.$parent.carousel.destroy(true, true);
  },
  methods: {
    initialiseSlider () {
      const swiper = new Swiper(`#${this.sliderName}`, this.options);
      this.$parent.carousel = swiper;

      if (this.activeIndexChange) {
        swiper.on(`activeIndexChange`, () => {
          this.$emit(`activeIndexChange`, swiper.activeIndex);
        });
      }
    },
  },
};
