import translations from './includes/translations';

export default {
  computed: {
    /*
    *   The language code being used by the site, e.g. 'en'
    *   This is the root of the language functionality, being used
    *   to automatically update components when changed, get the correct
    *   ACF fields and retrieving the correct posts
    */
    siteLanguage () {
      return this.$store.state.language.language;
    },
    currentMenu () {
      return this.siteLanguage && this.siteLanguage !== `en`
        ? `main-menu-${this.siteLanguage}`
        : `main-menu`;
    },
    stringTranslations () {
      // Return translations with the `name` as the key
      return this.$store.state.language.stringTranslations
        ? this.groupBy(this.$store.state.language.stringTranslations, `name`)
        : [];
    },
  },
  methods: {
    /*
    *  Get correct translation for a keyed value set in translations.js
    *  1st priority: Wordpress string translations
    *  2nd priority: Translations set in mixins.js/includes/translations.js
    *  Default: Key
    */
    translate (key, translateLang) {
      if (process.env.multilanguage) {
        const lang = translateLang || this.siteLanguage;
        return this.stringTranslations[key] && this.stringTranslations[key].length
          ? this.stringTranslations[key][0][lang]
          : translations[lang][key] || ``;
      }
      return key;
    },
    /*
    *  Set the language in $store
    */
    setLang (lang) {
      this.$store.dispatch(`language/setLanguage`, lang);
    },
    /*
    *  Get the current language name based on the slug
    */
    getLangName (slug) {
      const lang = this.$store.state.language.languages
        .find(language => language.slug === slug);
      return lang ? lang.name : false;
    },
  },
};
