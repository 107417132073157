import Vue from 'vue';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';

Vue.component(`l-map`, LMap);
Vue.component(`l-tile-layer`, LTileLayer);
Vue.component(`l-marker`, LMarker);

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require(`leaflet/dist/images/marker-icon-2x.png`),
  iconUrl: require(`leaflet/dist/images/marker-icon.png`),
  shadowUrl: require(`leaflet/dist/images/marker-shadow.png`),
});
