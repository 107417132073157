
export default {
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    src: {
      type: [String, Boolean],
      default: ``,
    },
    srcset: {
      type: String,
      default: ``,
    },
    alt: {
      type: String,
      default: ``,
    },
    title: {
      type: String,
      default: ``,
    },
    position: {
      type: String,
      default: `center center`,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    preload: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: `100%`,
    },
    height: {
      type: [Number, String],
      default: `auto`,
    },
  },
  computed: {
    imageSizes () {
      return process.env.sizes;
    },
    imageSrc () {
      return this.src || (this.post && this.post.image ? this.post.image.thumbnail : this.$store.state.config.default_page_image.sizes[`2048x2048`]);
    },
    imageSrcset () {
      return this.srcset || this.imageSrc || (this.post && this.post.image ? this.post.image.srcset : this.$store.state.config.default_page_image.sizes[`1078x1078`]);
    },
    imageAlt () {
      return this.alt ||
      (this.post && this.post.image
        ? this.post.image.alt
        : this.isUsingDefault
          ? this.$store.state.config.default_page_image.alt
          : ``);
    },
    imageTitle () {
      return this.title ||
        (this.post && this.post.image
          ? this.post.image.title
          : this.isUsingDefault
            ? this.$store.state.config.default_page_image.title
            : this.imageAlt);
    },
    isUsingDefault () {
      return this.imageSrc === this.$store.state.config.default_page_image.sizes[`2048x2048`];
    },
  },
};
