import dayjs from 'dayjs'

import 'dayjs/locale/en'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(advancedFormat)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.locale('en')

dayjs.tz.setDefault('Europe/London')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
